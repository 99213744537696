<template>
	<v-container
		fluid
		class="taskboard-container my-5"
	>
		<task-board-todo></task-board-todo>
	</v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import TaskBoardTodo from '../TaskBoardTodo/index.vue';

export default {
	name: 'TaskBoardContainer',
	components: {
		TaskBoardTodo,
	},
	data: () => ({
		//
	}),
	computed: {
		...mapGetters('userModule', ['getUser']),
	},
};
</script>

<style lang="scss" src="./scss/styles.scss"/>
