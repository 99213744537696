<template>
	<div class="post-comment-container">
		<div class="post-comment-avatar">
			<img :src="userAvatar" :alt="userName"/>
		</div>
		<textarea cols="1" rows="1" placeholder="Comment here..."></textarea>
	</div>
</template>

<script>
export default {
	name: 'PostComment',
	props: {
		userAvatar: {
			type: String,
			default: () => (''),
		},
		userName: {
			type: String,
			default: () => (''),
		},
	},
};
</script>

<style lang="scss" src="./scss/styles.scss"/>
