<template>
	<div class="comment-list-container" v-if="comments.length > 0">
		<div v-for="(comment, index) in comments"
			 :key="index" class="comment-item">
			<div class="comment-header">
				<div class="comment-avatar">
					<img :src="comment.commentatorAvatar" :alt="comment.commentatorName"/>
				</div>
				<span>{{ comment.commentatorName }}</span>
			</div>
			<div class="comment-footer">
				<div class="comment-message">{{ comment.message }}</div>
				<p>{{ comment.time }}</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'CommentList',
	props: {
		comments: {
			type: Array,
			default: () => ([]),
		},
	},
};
</script>

<style lang="scss" src="./scss/styles.scss"/>
