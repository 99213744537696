<template>
	<v-row justify="center">
		<v-dialog
			:value="dialog"
			persistent
			scrollable
			max-width="650px"
		>
			<div class="taskboard-tour-card">
				<div class="taskboard-tour-title">
					<h1 class="text-center">👋</h1>
					<h4>Hi Ishwar !!!</h4>
					<p>🎉🎉 <span>Here our new taskboard looks like</span> 🎉🎉</p>
				</div>
				<v-card-text>
					<div class="pyro">
						<div class="before"></div>
						<div class="after"></div>
					</div>
					<div class="taskboard-tour-details">
						<p>Here couples things got changes with new taskboard such as</p>
						<ul>
							<li>New design of taskboard such as look and feel aspects.</li>
							<li>Got everything aligned to user acceptations with every small details
								which the user may expect see on the new taskboard.
							</li>
							<li>This will hepls you analyse what all things are happening on each task.</li>
							<li>These how it look like each details of new taskboard</li>
						</ul>
						<div class="card-top-details">
							<div class="popup-col">
								<p>Taskboard card</p>
								<img src="@/assets/TaskboardCard.png" alt="Taskboard card"/>
							</div>
							<div class="popup-col">
								<p>Taskboard card with label</p>
								<img src="@/assets/TaskBoardLabel.png" alt="Taskboard card with label"/>
							</div>
						</div>
						<div class="card-middle-details">
							<p>Comments, Subtasks and Reporter of new taskboard as follows -</p>
							<div class="middle-col comments">
								<v-icon>far fa-comments</v-icon>
								<div class="comments-count">20</div>
							</div>
							<div class="middle-col subtasks">
								<v-icon>fas fa-clipboard-list</v-icon>
								<div class="subtasks-count">20</div>
							</div>
							<div class="taskboard-reportername">
								Reporter name - <span>DB</span>
							</div>
						</div>
						<div class="card-bottom-details">
							<p>Label will represent on new taskboard as follows -</p>
							<div class="taskboard-label">
								<div class="label-item">
									Frontend - <span class="label label-frontend"></span>
								</div>
								<div class="label-item">
									Backend - <span class="label label label-backend"></span>
								</div>
								<div class="label-item">
									Paid Project -<span class="label label-paid-project"></span>
								</div>
								<div class="label-item">
									Awaiting Client Feedback -
									<span class="label label-awaiting-client-feedback"></span>
								</div>
								<div class="label-item">
									On Hold Escaltion -<span class="label label-on-hold-escalation"></span>
								</div>
							</div>
							<p>Priority will represent on new taskboard as follows -</p>
							<div class="taskboard-label">
								<div class="priority-item">
									Low -
									<v-icon class="priority-low">mdi-chevron-double-down</v-icon>
								</div>
								<div class="priority-item">
									Medium -
									<v-icon class="priority-medium">mdi-equal</v-icon>
								</div>
								<div class="priority-item">
									High -
									<v-icon class="priority-high">mdi-chevron-double-up</v-icon>
								</div>
								<div class="priority-item">
									Critical -
									<v-icon class="priority-critical">mdi-chevron-triple-up</v-icon>
								</div>
							</div>
						</div>
					</div>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="green darken-1"
						text
						@click="closeDialog()"
					>
						Yes, I Got it
					</v-btn>
				</v-card-actions>
			</div>
		</v-dialog>
	</v-row>
</template>

<script>
export default {
	name: 'TaskboardTour',
	props: {
		dialog: {
			type: Boolean,
			default: () => (false),
		},
	},
	methods: {
		closeDialog() {
			this.$emit('close-dialog', false);
		},
	},
};
</script>

<style lang="scss" src="./scss/styles.scss"/>
