<template>
	<v-simple-table v-if="subTasks.length > 0" class="sub-task-table">
		<template v-slot:default>
			<thead>
			<tr>
				<th>Name</th>
				<th>Assignee</th>
				<th>Status</th>
			</tr>
			</thead>
			<tbody>
			<tr v-for="subtask in subTasks" :key="subtask.assigneeName">
				<td>{{ subtask.taskName }}</td>
				<td>{{ subtask.assigneeName }}</td>
				<td>
			  <span
				  :class="`subtask-status-${subtask.status.toLowerCase()}`">
				{{ subtask.status }}
            </span>
				</td>
			</tr>
			</tbody>
		</template>
	</v-simple-table>
</template>

<script>
export default {
	name: 'SubTaskList',
	props: {
		subTasks: {
			type: Array,
			default: () => ([]),
		},
	},
};
</script>

<style lang="scss" src="./scss/styles.scss"/>
