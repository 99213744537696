<template>
	<div class="card-middle">
		<div class="card-middle-first-col">
			<h4>{{ cardMiddleDetail.taskName }}</h4>
			<h6 class="mb-2">{{ cardMiddleDetail.category }}</h6>
			<p>{{ cardMiddleDetail.taskDesciption }}</p>
		</div>
		<div class="card-middle-second-col">
			<div class="card-top">
				<div class="card-label">
					<v-tooltip bottom color="secondary" v-for="label in taskLabel" :key="label">
						<template v-slot:activator="{ on, attrs }">
							<div class="label-dot"
								 v-bind="attrs" v-on="on"
								 :class="`task-${label.toLowerCase().replace(/\s/g, '-')}`"
							>
							</div>
						</template>
						<span>{{ label }}</span>
					</v-tooltip>
				</div>
				<h6>{{ cardMiddleDetail.createdAt }}</h6>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'CardMiddle',
	props: {
		cardMiddleDetail: {
			type: Object,
			default: () => ({}),
		},
	},
	computed: {
		taskLabel() {
			return this.cardMiddleDetail.label
				.filter((label) => label !== this.cardMiddleDetail.label[0]);
		},
	},
};
</script>
