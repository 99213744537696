<template>
	<div class="taskboard-card"
		 :class="`taskboard-card-${columnStatus} ${labelCardClass}`"
		 @dblclick="clickedOverlay(taskOverlay)"
	>
		<card-top
			:card-top-detail="task"
			:status="columnStatus"
			:icon="'mdi-close'"
		></card-top>
		<card-middle :card-middle-detail="task"></card-middle>
		<card-bottom
			:card-bottom-detail="task"
			:get-initials="getInitials(task.reporterName)"
			:priority-label="priorityLabel(task.priority)"
			:status="columnStatus"
			@click-comment="viewTaskboardComment"
			@click-subtask="viewTaskboardSubtask"
		></card-bottom>
		<div class="comment-container" v-show="visibleTaskboardComment">
			<comment-list :comments="commentList.comments"/>
			<post-comment
				:user-avatar="task.assigneAvatar"
				:user-name="task.assigneeName"/>
		</div>
		<div class="subtask-container" v-if="visibleTaskboardSubtask">
			<sub-task-list :sub-tasks="subTaskList.subtasks"/>
		</div>
	</div>
</template>

<script>
import CardTop from '../Taskboard/CardTop/index.vue';
import CardMiddle from '../Taskboard/CardMiddle/index.vue';
import CardBottom from '../Taskboard/CardBottom/index.vue';
import CommentList from '../CommentList/index.vue';
import PostComment from '../PostComment/index.vue';
import SubTaskList from '../SubTaskList/index.vue';

export default {
	name: 'TaskBoardCard',
	components: {
		CardTop,
		CardMiddle,
		CardBottom,
		CommentList,
		PostComment,
		SubTaskList,
	},
	props: {
		task: {
			type: Object,
			default: () => ({}),
		},
		columnStatus: {
			type: String,
			default: '',
		},
		commentList: {
			type: Object,
			default: () => ({}),
		},
		subTaskList: {
			type: Object,
			default: () => ({}),
		},
		taskOverlay: {
			type: Boolean,
		},
	},
	data: () => ({
		visibleTaskboardComment: false,
		visibleTaskboardSubtask: false,
	}),
	computed: {
		labelCardClass() {
			return this.task.label.length > 0 ? `taskboard-card-label-${this.task.label[0].toLowerCase()
				.replace(/\s/g, '-')}` : '';
		},
	},
	methods: {
		viewTaskboardComment(id) {
			this.visibleTaskboardSubtask = false;
			this.visibleTaskboardComment = !this.visibleTaskboardComment;
			if (this.visibleTaskboardComment) {
				this.$emit('view-taskboard-comment', id);
			}
		},
		viewTaskboardSubtask(id) {
			this.visibleTaskboardComment = false;
			this.visibleTaskboardSubtask = !this.visibleTaskboardSubtask;
			if (this.visibleTaskboardSubtask) {
				this.$emit('view-taskboard-subtask', id);
			}
		},
		priorityLabel(priority) {
			const priorityMapping = {
				Low: 'mdi-chevron-double-down',
				Medium: 'mdi-equal',
				High: 'mdi-chevron-double-up',
				Critical: 'mdi-chevron-triple-up',
			};
			return priorityMapping[priority];
		},
		getInitials(string) {
			const fullName = string.split(' ');
			let initials = fullName[0].substring(0, 1)
				.toUpperCase();
			if (fullName.length > 1) {
				initials += fullName[fullName.length - 1].substring(0, 1)
					.toUpperCase();
			}
			return initials;
		},
		clickedOverlay(value) {
			this.$emit('clicked-overlay', !value);
		},
	},
};
</script>

<style lang="scss" src="./scss/styles.scss"/>
