<template>
	<div class="card-bottom">
		<div class="card-bottom-inside">
			<div class="card-bottom-panel" @click="viewComment(cardBottomDetail.taskId)">
				<v-tooltip bottom color="secondary">
					<template v-slot:activator="{ on, attrs }">
						<div v-bind="attrs" v-on="on">
							<v-icon
								class="icon-comments"
								:class="`color-${status}`"
							>
								far fa-comments
							</v-icon>
							<span
								class="comments"
								:class="`color-${status}`">{{ cardBottomDetail.commentsCount }}</span>
						</div>
					</template>
					<span>Comments</span>
				</v-tooltip>
			</div>
			<div class="card-priority" v-if="cardBottomDetail.priority">
				<v-tooltip bottom color="secondary">
					<template v-slot:activator="{ on, attrs }">
						<v-icon
							:class="`priority-${cardBottomDetail.priority.toLowerCase()}`"
							v-bind="attrs"
							v-on="on"
						>
							{{ priorityLabel }}
						</v-icon>
					</template>
					<span>{{ cardBottomDetail.priority }}</span>
				</v-tooltip>
			</div>
		</div>
		<div class="card-bottom-inside">
			<div class="card-reporter">
				<v-tooltip bottom color="secondary">
					<template v-slot:activator="{ on, attrs }">
						<div class="reporter-name" v-bind="attrs" v-on="on">
							{{ getInitials }}
						</div>
					</template>
					<span>Reporter: {{ cardBottomDetail.reporterName }}</span>
				</v-tooltip>
			</div>
			<div class="card-bottom-panel" @click="viewSubtask(cardBottomDetail.taskId)">
				<v-tooltip bottom color="secondary">
					<template v-slot:activator="{ on, attrs }">
						<div v-bind="attrs" v-on="on">
							<v-icon
								class="icon-subtasks"
								:class="`color-${status}`"
							>
								fas fa-clipboard-list
							</v-icon>
							<span
								class="subtasks"
								:class="`color-${status}`">{{ cardBottomDetail.subTaskCount }}</span>
						</div>
					</template>
					<span>Sub tasks</span>
				</v-tooltip>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'CardBottom',
	props: {
		cardBottomDetail: {
			type: Object,
			default: () => ({}),
		},
		priorityLabel: {
			type: String,
			default: () => (''),
		},
		getInitials: {
			type: String,
			default: () => (''),
		},
		status: {
			type: String,
			default: () => (''),
		},
	},
	methods: {
		viewComment(taskId) {
			this.$emit('click-comment', taskId);
		},
		viewSubtask(taskId) {
			this.$emit('click-subtask', taskId);
		},
	},
};

</script>
