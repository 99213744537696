<template>
	<div class="taskboard-todo-container">
		<div v-for="(column, index) in getTaskboardDetail" :key="column.title"
			 class="taskboard-todo-column"
		>
			<div class="column-status-col"
				 :class="{'last-todo-column': index === getTaskboardDetail.length - 1}"
				 v-if="column.visible">
				<div class="taskboard-todo-column-title"
					 :class="`taskboard-todo-title-${column.status.toLowerCase()}`">
					<span class="column-title">{{ column.title }} ({{ column.tasks.length }})</span>
					<span class="task-column-cogs">
              <dropdown-menu :offset="true">
                <template #button>
                  <v-btn icon>
                    <v-icon>fas fa-cogs</v-icon>
                  </v-btn>
                </template>
                <template #list>
                  <taskboard-status-dropdown
					  :taskStatus="getTaskboardStatusDetails">
                  </taskboard-status-dropdown>
                </template>
              </dropdown-menu>
            </span>
				</div>
				<draggable-layout :listItem="column.tasks" :ghostClass="'ghost-card'">
					<task-board-card
						v-for="(task) in column.tasks"
						:key="task.taskId"
						:task="task"
						:column-status="column.status.toLowerCase()"
						:comment-list="getTaskboardComment"
						:sub-task-list="getTaskboardSubTask"
						:task-overlay="overlay"
						@view-taskboard-comment="viewTaskboardComment"
						@view-taskboard-subtask="viewTaskboardSubTask"
						@clicked-overlay="openTaskborderOverlay"
					>
					</task-board-card>
				</draggable-layout>
			</div>
		</div>
		<v-overlay
			class="taskboard-overlay-container"
			:value="overlay"
		>
			<div class="taskboard-overlay" transition="scroll-y-transition">
				<p @click="closeTaskboardOverlay()">Hello Overlay</p>
			</div>
		</v-overlay>
		<taskboard-tour :dialog="dialog" @close-dialog="closeDialog"></taskboard-tour>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DraggableLayout from '../Draggable/index.vue';
import TaskBoardCard from '../TaskBoardCard/index.vue';
import DropdownMenu from '../../../components/DropdownMenu/index.vue';
import TaskboardStatusDropdown from '../Taskboard/TaskboardStatusDropdown/index.vue';
import TaskboardTour from '../TaskboardTour/index.vue';

export default {
	name: 'TaskboardTodo',
	components: {
		DraggableLayout,
		TaskBoardCard,
		DropdownMenu,
		TaskboardStatusDropdown,
		TaskboardTour,
	},
	data: () => ({
		overlay: false,
		dialog: false,
	}),
	computed: {
		...mapGetters('taskBoardModule', ['getTaskBoardDetails', 'getTaskboardStatus']),
		...mapGetters('commentsModule', ['getTaskboardComments']),
		...mapGetters('subTasksModule', ['getTaskboardSubTasks']),
		getTaskboardDetail() {
			return this.getTaskBoardDetails;
		},
		getTaskboardStatusDetails() {
			return this.getTaskboardStatus;
		},
		getTaskboardComment() {
			return this.getTaskboardComments;
		},
		getTaskboardSubTask() {
			return this.getTaskboardSubTasks;
		},
	},
	watch: {
		getTaskboardComment(newComments) {
			return newComments;
		},
		getTaskboardSubTask(newSubTasks) {
			return newSubTasks;
		},
	},
	mounted() {
		this.setTaskBoard();
	},
	methods: {
		...mapActions('taskBoardModule', ['setTaskBoard']),
		...mapActions('commentsModule', ['setTaskboardComments']),
		...mapActions('subTasksModule', ['setTaskboardSubTasks']),
		viewTaskboardComment(taskId) {
			this.setTaskboardComments(taskId);
		},
		viewTaskboardSubTask(taskId) {
			this.setTaskboardSubTasks(taskId);
		},
		openTaskborderOverlay(value) {
			this.overlay = value;
		},
		closeTaskboardOverlay() {
			this.overlay = false;
		},
		closeDialog() {
			this.dialog = false;
		},
	},
};
</script>

<style lang="scss" src="./scss/styles.scss"/>
