<template>
	<v-menu
		transition="slide-x-transition"
		down
		left
		:close-on-content-click="true"
		:offset-y="offset"
	>
		<template v-slot:activator="{ on, attrs }">
			<div v-bind="attrs" v-on="on">
				<slot name="button"></slot>
			</div>
		</template>
		<slot name="list"></slot>
	</v-menu>
</template>

<script>
export default {
	name: 'DropdownMenu',
	props: {
		offset: {
			type: Boolean,
			default: () => (false),
		},
	},
};
</script>
