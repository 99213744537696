<template>
	<draggable :list="listItem" :animation="200" ghost-class="ghostClass" group="tasks">
		<slot></slot>
	</draggable>
</template>

<script>
import draggable from 'vuedraggable';

export default {
	name: 'DraggableLayout',
	components: {
		draggable,
	},
	props: {
		listItem: {
			type: Array,
			default: () => ({}),
		},
		ghostClass: {
			type: String,
			default: () => (''),
		},
	},
};
</script>

<style lang="scss" src="./scss/styles.scss"/>
