<template>
	<v-list>
		<v-list-item
			v-for="(item, index) in taskStatus"
			:key="index"
			class="status-list"
			link
		>
			<v-list-item-title class="status-list-title" v-if="index === 0">
				<v-menu
					transition="slide-x-transition"
					open-on-hover
					down
					left
					:close-on-content-click="true"
					:offset-x="true"
				>
					<template v-slot:activator="{ on, attrs }">
						<div link v-bind="attrs" v-on="on">
							<v-icon>{{ item.icon }}</v-icon>
							<span>{{ item.title }}</span>
							<v-icon>mdi-chevron-left</v-icon>
						</div>
					</template>
					<v-list>
						<v-list-item
							v-for="(subItem, subIndex) in item.status"
							:key="subIndex"
							link
							class="status-list"
						>
							<v-list-item-title class="status-list-title">
								<v-icon>{{ subItem.icon }}</v-icon>
								<span>{{ subItem.title }}</span>
							</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>
			</v-list-item-title>
			<v-list-item-title v-else class="status-list-title">
				<v-icon>{{ item.icon }}</v-icon>
				<span>{{ item.title }}</span>
			</v-list-item-title>
		</v-list-item>
	</v-list>
</template>

<script>
export default {
	name: 'TaskboardStatusDropdown',
	props: {
		taskStatus: {
			type: Array,
			default: () => ([]),
		},
	},
};
</script>
