<template>
	<div class="card-top">
		<div class="card-heading">
			<div class="card-avatar">
				<img :src="cardTopDetail.assigneAvatar" :alt="cardTopDetail.assigneName"/>
			</div>
			<span class="card-name">{{ cardTopDetail.assigneName }}</span>&nbsp;
			<span class="card-badge" :class="`text-color-${status}`">
        TD-{{ cardTopDetail.taskId }}
      </span>
		</div>
		<div class="card-close">
			<v-icon>{{ icon }}</v-icon>
		</div>
	</div>
</template>

<script>
export default {
	name: 'CardTop',
	props: {
		cardTopDetail: {
			type: Object,
			default: () => ({}),
		},
		status: {
			type: String,
			default: () => (''),
		},
		icon: {
			type: String,
			default: () => (''),
		},
	},
};
</script>
